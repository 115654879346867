import React from "react"
import Layout from "../layout/Layout"
import SEO from "../components/seo/seo"
import HeroSection from "../homeSections/HeroSection"
import AboutSection from "../homeSections/AboutSection"
import HistorySection from "../homeSections/HistorySection"
import ChefSection from "../homeSections/ChefSection"
import EngagementSection from "../homeSections/EngagementSection"
import ReservationSection from "../homeSections/ReservationSection"
import ContactSection from "../homeSections/ContactSection"
import CyberBotsScript from '../components/CyberBotsScript'; 

export default function Home() {
  return (
    <Layout>
      <SEO
        title="Home"
        description="Absolis - nous équipons nos clients de matériel informatique fiable et les aidons à disposer d'un environnement de travail solide et sécurisé."
      />
      <main>
        <HeroSection />
        <AboutSection />
        <HistorySection />
        <ChefSection />
        <ReservationSection />
        <EngagementSection />

        <ContactSection />
      </main>
      <CyberBotsScript />
    </Layout>
  )
}
