import React, { useEffect } from 'react';

const CyberBotsScript = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://bots.cyberesia.com/widgets/chatbox.js?id=clsw1b9bg0001s87ifp0xmyh5";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return null;
};

export default CyberBotsScript;
