export const menuItemsData = [
  {
    id: 0,
    title: "Maintenance informatique",
    list: [
      {
        name: "Maintenance à distance pour PMEs",
        desc:
          "Forfait mensuel illimité . par poste . y compris dépannage prioritaire . installation, mises à jour, support et assistance garantis",
        price: "Nous contacter",
      },
      {
        name: "Installation standard PCs, Laptops et Serveurs",
        desc:
          "Forfait par installation . configuration standard . enregistrement dans système de sauvegarde",
        price: "Nous contacter",
      },
      {
        name: "Interventions personnalisées",
        desc:
          "Tarif horaire (hors contrats). configuration hardware, OS, logiciels, réseaux et sauvegardes",
        price: "Nous contacter",
      },
      {
        name: "Contrat de maintenance 6h",
        desc:
          "Intervention à la demi-journée . minimum 2 par mois . frais de parking non inclus . prix par ½ jour",
        price: "Nous contacter",
      },
      {
        name: "Contrat de maintenance 15h",
        desc:
          "Intervention à la demi-journée . minimum 5 par mois . frais de parking non inclus . prix par ½ jour",
        price: "Nous contacter",
      },
      {
        name: "Contrat de maintenance 24h",
        desc:
          "Intervention à la demi-journée . minimum 8 par mois . frais de parking non inclus . prix par ½ jour",
        price: "Nous contacter",
      },
      {
        name: "Contrat de maintenance 30h",
        desc:
          "Intervention à la demi-journée . minimum 10 par mois . frais de parking non inclus . prix par ½ jour",
        price: "Nous contacter",
      },
    ],
  },

  {
    id: 1,
    title: "Dépannage et support",
    list: [
      {
        name: "Forfait Dépannage sur site",
        desc:
          "Forfait mensuel illimité . par poste . intervention dans les 3 heures",
        price: "Nous contacter",
      },
      {
        name: "Dépannage téléphonique",
        desc:
          "Hotline dépannage . forfait minute",
        price: "Nous contacter",
      },
      {
        name: "Dépannage Serveurs sur site",
        desc:
          "Intervention immédiate sur site . tarif horaire . déplacement non compris",
        price: "Nous contacter",
      },
      {
        name: "Support et assistance logiciels",
        desc:
          "Hotline . prise de contrôle à distance si besoin",
        price: "Nous contacter",
      },
    ],
  },
  {
    id: 2,
    title: "promotions et Vente de matériel",
    list: [
      {
        name: "Vente de matériel informatique",
        desc:
          "Nous avons accès à des tarifs défiant toute concurrence",
        price: "Nous contacter",
      },
      {
        name: "Ventes Promos",
        desc:
          "Nous vous ferons part ici aussi de nos promotions exceptionnelles",
        price: "Nous contacter",
      },
    ],
  },
  {
    id: 3,
    title: "Formule Administrateur IT ",
    list: [
      {
        name: "IT One",
        desc:
          "Rôle d'administrateur délégué dédié à votre société . un jour par mois . sur site",
        price: "Nous contacter",
      },
      {
        name: "IT Four",
        desc:
          "Rôle d'administrateur délégué dédié à votre société . quatre jours par mois . sur site",
        price: "Nous contacter",
      },
      {
        name: "IT Remote",
        desc:
          "Nous endossons le rôle d'administrateur IT pour votre société . à distance depuis nos bureaux",
        price: "Nous contacter",
      },
    ],
  },
  
]
