import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import { fadeLeft, fadeBottomFaster } from "../components/animations/Animation"
import Container from "../layout/container/Container"
import MainParagraph from "../components/typography/MainParagraph"

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(3, 1fr);
  @media (max-width: 1059px) {
    display: flex;
    flex-direction: column;
  }
`
const StyledTitleWrapper = styled.div`
  grid-column: 1/6;
  grid-row: 1/4;
  padding: 120px;
  @media (max-width: 1279px) {
    padding: 80px;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
  }
`
const StyledContentWrapper = styled.div`
  grid-column: 6/13;
  grid-row: 1/4;
  padding: 120px;
  @media (max-width: 1279px) {
    padding: 80px;
  }
  @media (max-width: 1059px) {
    padding: 0 80px 80px 80px;
  }
  @media (max-width: 768px) {
    padding: 0 40px 80px 40px;
  }
  @media (max-width: 569px) {
    padding: 0 20px 80px 20px;
  }
`
const StyledTitleText = styled.h2`
  color: ${props => props.theme.colors.dark};
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 80px;
  position: relative;
  &:before {
    content: "Notre";
    position: absolute;
    top: -3rem;
    left: -2rem;
    color: ${props => props.theme.colors.redAbso};
    font-size: 60px;
    z-index: -1;
    @media (max-width: 489px) {
      top: -2rem;
      font-size: 36px;
    }
  }
  @media (max-width: 1059px) {
    margin-left: 2rem;
  }
  @media (max-width: 768px) {
    margin-left: 0;
  }
  @media (max-width: 489px) {
    font-size: 60px;
  }
`

const StyledImgDiv = styled(BackgroundImage)`
  min-height: 480px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;
  background-attachment: fixed;
`

export default function EngagementSection() {
  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "index/absolis-bubbles.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1900) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const imageData = data.file.childImageSharp.fluid

  // GSAP
  let headerRef = useRef(null)
  let contentRef = useRef(null)

  useEffect(() => {
    fadeLeft(headerRef.current, headerRef.current)
    fadeBottomFaster(contentRef.current, contentRef.current)
  }, [])

  return (
    <section>
      <Container>
        <StyledGrid>
          <StyledTitleWrapper ref={headerRef}>
            <StyledTitleText>Engagement</StyledTitleText>
          </StyledTitleWrapper>
          <StyledContentWrapper ref={contentRef}>
            <MainParagraph>
            Nous intervenons sur toutes les technologies, anciennes ou modernes, 
            et au passage nous assurons que votre identité est protégée et votre 
            équipement informatique sécurisé (antivirus, antimalwares, backup, 
            firewall, protection contre les vulnérabilités, etc.).
            </MainParagraph>
          </StyledContentWrapper>
        </StyledGrid>
      </Container>
      <StyledImgDiv Tag="section" fluid={imageData}></StyledImgDiv>
    </section>
  )
}
