import React from "react"
import styled from "styled-components"

const StyledParagraph = styled.p`
  color: ${props => props.theme.colors.grey};
  line-height: 1.8;
`

export default function Address() {
  return (
    <div>
      <StyledParagraph>Absolis SA</StyledParagraph>
      <StyledParagraph>Route de Trélex 10</StyledParagraph>
      <StyledParagraph>1266 Duiller</StyledParagraph>
    </div>
  )
}
