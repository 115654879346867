export const footerLinksData = [
  {
    path: "/privacy",
    name: "Politique de confidentialité",
  },
  {
    path: "/about",
    name: "À propos de nous",
  },
  {
    path: "/nos-prestas",
    name: "Nos Prestations",
  },
  {
    path: "/vente",
    name: "Vente",
  },
  {
    path: "/reservation",
    name: "Rendez-vous",
  },
  {
    path: "/contact",
    name: "Carrières",
  },
  {
    path: "/history",
    name: "Partenaires",
  },
]
